import { ConfigProvider, theme } from "antd";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layouts from "./components/common/Layout";
import AddSubcription from "./pages/AddSubs";
import Banners from "./pages/Banners";
import Categories from "./pages/Categories";
import Dashboard from "./pages/Dashboard";
import Episodes from "./pages/Episodes";
import Genres from "./pages/Genres";
import Login from "./pages/Login";
import Movies from "./pages/Movies";
import Notification from "./pages/Notification";
import Series from "./pages/Series";
import ShortBanner from "./pages/ShortBanner";
import SubCategories from "./pages/SubCategories";
import SystemValues from "./pages/SystemValues";
import TopBanner from "./pages/TopBanner";
import Users from "./pages/Users";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00D119",
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Layouts>
              <Dashboard />
            </Layouts>
          }
        />
        <Route
          path="/system-values"
          element={
            <Layouts>
              <SystemValues />
            </Layouts>
          }
        />
        <Route
          path="/banners"
          element={
            <Layouts>
              <Banners />
            </Layouts>
          }
        />
        <Route
          path="/categories"
          element={
            <Layouts>
              <Categories />
            </Layouts>
          }
        />{" "}
        <Route
          path="/subscription"
          element={
            <Layouts>
              <AddSubcription />
            </Layouts>
          }
        />
        <Route
          path="/subcategories/:id/:name"
          element={
            <Layouts>
              <SubCategories />
            </Layouts>
          }
        />
        <Route
          path="/genres"
          element={
            <Layouts>
              <Genres />
            </Layouts>
          }
        />
        <Route
          path="/movies"
          element={
            <Layouts>
              <Movies />
            </Layouts>
          }
        />
        <Route
          path="/series"
          element={
            <Layouts>
              <Series />
            </Layouts>
          }
        />
        <Route
          path="/episodes/:id"
          element={
            <Layouts>
              <Episodes />
            </Layouts>
          }
        />
        <Route
          path="/users"
          element={
            <Layouts>
              <Users />
            </Layouts>
          }
        />
        <Route
          path="/topbanner"
          element={
            <Layouts>
              <TopBanner />
            </Layouts>
          }
        />
        <Route
          path="/shortBanner"
          element={
            <Layouts>
              <ShortBanner />
            </Layouts>
          }
        />
        <Route
          path="/notification"
          element={
            <Layouts>
              <Notification />
            </Layouts>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
